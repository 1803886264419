import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  NativeSelect,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';

const CustomBox = styled(Box)(({ theme }) => ({
  borderRadius: '5px',
  background: 'rgba(255, 255, 255, .4)',
  marginTop: 8,
}));

export function DepositPlaceholder() {
  return (
    <Box sx={{ position: 'relative' }}>
      {/* <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          background: 'rgba(0 ,0, 0, .8)',
          borderRadius: '5px',
        }}
      >
        <span className="loader" style={{ marginTop: '30%' }} />
      </Box> */}

      <Box className="shimmer-effect" sx={{ height: '35px', width: '100%' }} />
      <Box className="shimmer-effect" sx={{ mt: '10px', height: '40px' }} />
      <Box className="shimmer-effect" sx={{ mt: 1, height: '66px' }} />
      <Box className="shimmer-effect" sx={{ mt: 1, height: '61px' }} />
      <Box className="shimmer-effect" sx={{ mt: 1, height: '61px' }} />
      <Box className="shimmer-effect" sx={{ mt: 1, height: '66px' }} />
      <Box className="shimmer-effect" sx={{ mt: 2, mb: 1, height: '24px' }} />
      <Box className="shimmer-effect" sx={{ mt: 1, height: '56px' }} />
      <Box className="shimmer-effect" sx={{ mt: 1, height: '218px' }} />
      <Box className="shimmer-effect" sx={{ mt: '10px', height: '55px' }} />
    </Box>
  );
}
