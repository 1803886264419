import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  NativeSelect,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { useParams } from 'react-router';
import { useSelector, useDispatch } from '../../../../redux/store';

import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import { UploadSingleFile } from '../../../../components/upload';
import { openTransactionsDialog } from '../../../../redux/slices/transactionsDialog';
import { fCurrency, fNumberCommaSeparated } from '../../../../utils/formatNumber';
import Iconify from '../../../../components/Iconify';

import { closeLoadingScreen, openLoadingScreen } from '../../../../redux/slices/settings';

// Drawer
import ConfirmDepositDrawer from './ConfirmDepositDrawer';
import CustomModal from '../../../../components/CustomModal';
import Image from '../../../../components/Image';

import palette from '../../../../theme/palette';
import PopupQrCode from './PopupQrCode';
import { renderWithLinks } from '../../../../utils/formatString';
import { DepositPlaceholder } from './DepositPlaceholder';

const TypographyCustom = styled(Typography)({
  color: palette.transactionDialog.deposit.inputLabel,
  fontSize: '14px',
});

const SmallBlackText = styled(Typography)(({ theme }) => ({
  color: palette.transactionDialog.deposit.smallText,
  fontSize: '14px',
  fontWeight: 600,
}));

const LargeBlackText = styled(Typography)(({ theme }) => ({
  color: palette.transactionDialog.deposit.largeText,
  fontSize: '20px',
  fontWeight: 700,
}));

const SuggestedTopupBox = styled(Box)(({ theme }) => ({
  backgroundColor: palette.transactionDialog.deposit.suggestedTopupBg,
  padding: '16px 4px 11px',
  borderRadius: '6px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderWidth: '3px',
  borderStyle: 'solid',
}));

const SuggestedTopupTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  wordBreak: 'break-all',
  color: palette.transactionDialog.deposit.suggestedTopupTxt,
}));

const SmallPromoBox = styled(Box)(({ theme }) => ({
  background: palette.transactionDialog.deposit.smallPromoBox.bg,
  fontSize: '10px',
  fontWeight: 700,
  height: '16px',
  padding: '0 4px',
  right: '4px',
  top: '-4px',
  transform: 'scale(.8)',
  position: 'absolute',
  zIndex: 10,
  color: palette.transactionDialog.deposit.smallPromoBox.color,
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
}));

const BonusBox = styled(Grid)(({ theme }) => ({
  color: palette.transactionDialog.deposit.bonusBox.color,
  background: palette.transactionDialog.deposit.bonusBox.bg,
  padding: '9px',
  fontSize: '14px',
  marginTop: '20px',
  fontWeight: 700,
  borderRadius: '10px',
  border: `3px solid ${palette.transactionDialog.deposit.bonusBox.border}`,
}));

const StyledSelect = styled(NativeSelect)(({ theme }) => ({
  width: '100%',
  color: palette.transactionDialog.deposit.select.color,
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: palette.transactionDialog.deposit.select.bg,
  padding: '4px 11px',
  border: `1px solid ${palette.transactionDialog.deposit.select.border} !important`,
  borderRadius: '6px',
}));

const SelectedBankBox = styled(Box)(({ theme }) => ({
  background: palette.transactionDialog.deposit.selectedBankBox.bg,
  border: `1px solid ${palette.transactionDialog.deposit.selectedBankBox.border}`,
  padding: '9px',
  fontSize: '14px',
  marginTop: 8,
  fontWeight: 700,
  borderRadius: '10px',
}));

const DepositInputBox = styled(Stack)(({ theme }) => ({
  background: palette.transactionDialog.deposit.depositInputBox.bg,
  border: `1px solid ${palette.transactionDialog.deposit.depositInputBox.border}`,
  borderRadius: '6px',
  padding: '4px 11px',
  alignItems: 'center',
}));

const DepositInput = styled(InputBase)(({ theme }) => ({
  color: palette.transactionDialog.deposit.depositInput.color,
  flexGrow: 1,
  fontSize: '20px',
  fontWeight: 700,
  padding: 0,
  '&::placeholder': {
    color: '#000 !important',
    opacity: 1,
  },
}));

const BankRefNumber = styled(TextField)(({ theme }) => ({
  color: palette.transactionDialog.deposit.bankRefNumber.color,
  flexGrow: 1,
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: '6px',
  padding: '4px 11px',
  width: '100%',
  background: '#000',
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${palette.transactionDialog.deposit.bankRefNumber.border} !important`,
  },
  '& .MuiOutlinedInput-root': {
    background: palette.transactionDialog.deposit.bankRefNumber.outlineBg,
  },
  '& input': {
    color: `${palette.transactionDialog.deposit.bankRefNumber.color} !important`,
    fontWeight: 600,
    '&::placeholder': {
      color: `rgba(255, 255, 255, .2) !important`,
    },
  },
}));

const ConfirmButtonContainer = styled(Grid)(({ theme }) => ({
  padding: '8px 12px',
  position: 'relative',
  backgroundColor: palette.transactionDialog.deposit.confirmBtn,
  borderRadius: '10px',
  marginTop: '10px',
  cursor: 'pointer',
}));

const DepositBtnText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: palette.transactionDialog.deposit.bepositBtnText,
  fontWeight: 700,
}));

const DepositBtnAmount = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  color: palette.transactionDialog.deposit.depositAmt,
  fontWeight: 700,
  marginRight: 2,
  wordBreak: 'break-all',
}));

const Deposit = () => {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const params = useParams();

  const { memberBalance } = useSelector((x) => x.lookup);

  const [depositAmount, setDepositAmount] = useState(100);

  const [confirmDeposit, setConfirmDeposit] = useState(false);

  const [currencyTopup, setCurrencyTopup] = useState([]);

  const [overrideCurrencyTopup, setOverrideCurrencyTopup] = useState([]);

  const [currency, setCurrency] = useState('');

  const [bankTypeName, setBankTypeName] = useState('');

  const [minimumDeposit, setMinimumDeposit] = useState('');

  const [maximumDeposit, setMaximumDeposit] = useState('');

  const [paymentMethods, setPaymentMethods] = useState({});

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const [bankList, setBankList] = useState();

  const [selectedBank, setSelectedBank] = useState(null);

  const [errors, setErrors] = useState({});

  const [bankSlip, setBankSlip] = useState(null);

  const [promotions, setPromotions] = useState(null);

  const [selectedPromotion, setSelectedPromotion] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showBankQrCode, setShowBankQrCode] = useState(null);

  const [selectedBankSource, setSelectedBankSource] = useState('');

  const [bankSourceList, setBankSourceList] = useState({});

  const [isAutoUpdatePromotion, setIsAutoUpdatePromotion] = useState(false);

  const [maintenanceRemark, setMaintenanceRemark] = useState('');

  const [bankInactiveInstruction, setBankInactiveInstruction] = useState('');

  const [customWAButton, setCustomWAButton] = useState({ title: '', url: '' });

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: '',
  });

  const [referenceNumber, setReferenceNumber] = useState('');

  const [topPayQrCode, setTopPayQrCode] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // const [withdrawRequirement, setWithdrawRequirement] = useState(null);

  // const [isCalculatingReq, setIsCalculatingReq] = useState(false);

  const filteredPromotions = useMemo(() => {
    if (isAutoUpdatePromotion && promotions?.length && Number(depositAmount || 0) > 0) {
      const _promotions = promotions?.filter(
        (p) =>
          Number(depositAmount) >= Number(p?.min_deposit || 0) &&
          Number(p?.max_deposit === 0 || Number(depositAmount) <= Number(p?.max_deposit))
      );

      return _promotions;
    }

    return promotions;
  }, [depositAmount, isAutoUpdatePromotion, promotions]);

  const isHideDeposit = useMemo(
    () => maintenanceRemark || bankInactiveInstruction,
    [maintenanceRemark, bankInactiveInstruction]
  );

  useEffect(() => {
    if (filteredPromotions?.length) {
      setSelectedPromotion(filteredPromotions[0]?.id);
    } else setSelectedPromotion(0);
  }, [filteredPromotions]);

  useEffect(() => {
    getDepositMethods();
  }, []);

  // useEffect(() => {
  //   if (memberBalance?.currencyCode.toUpperCase() === 'MMK' || memberBalance?.currencyCode.toUpperCase() === 'VND') {
  //     setAmountInThousandths(true);
  //   }
  // }, [memberBalance]);

  useEffect(() => {
    if (selectedPaymentMethod && Object.keys(paymentMethods)?.length > 0) {
      setBankList(paymentMethods?.[selectedPaymentMethod]?.Bank);
      setSelectedBank(paymentMethods?.[selectedPaymentMethod]?.Bank[0]?.BankAccount?.id);
      const bankSourceCodes = paymentMethods?.[selectedPaymentMethod]?.Bank[0]?.deposit_source_bank_codes;
      if (bankSourceCodes && Object.keys(bankSourceCodes)?.length) {
        setBankSourceList(bankSourceCodes);
        setSelectedBankSource(Object.keys(bankSourceCodes)[0]);
      } else {
        setBankSourceList({});
        setSelectedBankSource('');
      }
    }
  }, [paymentMethods, selectedPaymentMethod]);

  useEffect(() => {
    if (bankList) {
      const _selectedBank = bankList.find((f) => f.BankAccount?.id === selectedBank);
      setMaintenanceRemark('');
      setBankInactiveInstruction('');
      setCustomWAButton({ title: '', url: '' });

      if (_selectedBank) {
        const {
          min_deposit: minDeposit,
          max_deposit: maxDeposit,
          is_maintenance: isMaintenance,
          maintenance_remark: _maintenanceRemark,
          show_instruction: showInstruction,
          active,
          custom_button_title: customButtonTitle,
          custom_button_url: customButtonUrl,
        } = _selectedBank?.BankAccount;

        /**
         * set bank/promotion minimum deposit as default
         * whichever is greater
         *  */
        const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

        const promoMaxDeposit = _selectedPromotion?.max_deposit || 0;

        const _maxDeposit = maxDeposit || 0;

        const _minDeposit = minDeposit || 0;

        const sanitizedPromoMaxDeposit = parseFloat(promoMaxDeposit.toString().replace(/[^0-9.]/g));
        const sanitizedBankMaxDeposit = parseFloat(_maxDeposit.toString().replace(/[^0-9.]/g));

        const maxDepositValue =
          sanitizedBankMaxDeposit <= sanitizedPromoMaxDeposit ? sanitizedBankMaxDeposit : sanitizedPromoMaxDeposit;

        setMinimumDeposit(
          // amountInThousandths
          //   ? parseFloat(minDeposit.replace(/[^0-9.]/g, '')) * 1000 :
          parseFloat(_minDeposit.toString().replace(/[^0-9.]/g, ''))
        );
        setMaximumDeposit(
          // amountInThousandths
          //   ? parseFloat(maxDeposit.replace(/[^0-9.]/g, '')) * 1000 :
          // parseFloat(maxDepositValue)
          parseFloat(_maxDeposit.toString().replace(/[^0-9.]/g, ''))
        );

        const promoMinDeposit = _selectedPromotion?.min_deposit || 0;

        const sanitizedPromoMinDeposit = parseFloat(promoMinDeposit.toString().replace(/[^0-9.]/g));
        const sanitizedBankMinDeposit = parseFloat(_minDeposit.toString().replace(/[^0-9.]/g));

        const depositValue =
          sanitizedBankMinDeposit >= sanitizedPromoMinDeposit ? sanitizedBankMinDeposit : sanitizedPromoMinDeposit;

        if (_selectedBank?.OverrideCurrencyTopup && _selectedBank?.OverrideCurrencyTopup?.length) {
          // console.log(_selectedBank?.OverrideCurrencyTopup);
          setOverrideCurrencyTopup(_selectedBank?.OverrideCurrencyTopup);
        } else setOverrideCurrencyTopup([]);

        if (isMaintenance && Number(isMaintenance) === 1) setMaintenanceRemark(_maintenanceRemark || '');

        if (Number(active || 0) === 0) setBankInactiveInstruction(showInstruction || '');

        setCustomWAButton({ title: customButtonTitle || '', url: customButtonUrl || '' });

        if (!isAutoUpdatePromotion) setDepositAmount(depositValue);
      } else {
        setMinimumDeposit('');
        setMaximumDeposit('');
      }
    }
  }, [bankList, isAutoUpdatePromotion, promotions, selectedBank, selectedPromotion]);

  useEffect(() => {
    let valid = true;
    const _errors = {};

    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);
    // promo minimum deposit
    const minDeposit = _selectedPromotion?.min_deposit;

    if (selectedBank) {
      if (!depositAmount) {
        valid = false;
        _errors.depositAmount = translate('x_is_required', { x: `${translate('deposit')} ${translate('amount')}` });
      } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < parseFloat(minDeposit)) {
        valid = false;
        _errors.depositAmount = `${translate('minimum_amount_is_x_y', { x: currency, y: minDeposit })}. (${translate(
          'from_selected_promotion'
        )})`;
      } else if (
        parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit ||
        parseFloat(depositAmount.toString().replaceAll(',', '')) > maximumDeposit
      ) {
        // if maximumDeposit === 0 means no min/max deposit
        if (maximumDeposit !== 0) {
          valid = false;
          _errors.depositAmount = translate('deposit_amount_between_x_and_y', { x: minimumDeposit, y: maximumDeposit });
        } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit) {
          valid = false;
          _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minimumDeposit });
        }
      } else {
        _errors.depositAmount = '';
      }
    }

    setErrors(_errors);
  }, [depositAmount, selectedPromotion, minimumDeposit, maximumDeposit]);

  const selectedBankData = useMemo(() => {
    if (selectedBank && bankList?.length) {
      return bankList.find((f) => f.BankAccount.id === selectedBank);
    }
    return {};
  }, [selectedBank, bankList]);

  const getDepositMethods = () => {
    dispatch(openLoadingScreen());
    setIsLoading(true);

    conObj
      .post('transactions/deposits.json')
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            // success
            const {
              payment_method: paymentMethod,
              promotions,
              CurrencyTopup,
              alert,
              auto_update_promotion_list: autoUpdatePromotionList,
            } = res.data?.data?.data;

            setIsAutoUpdatePromotion(autoUpdatePromotionList || false);

            if (alert) {
              setShowAlert({ show: true, message: alert });
            }

            // set currency topup
            setCurrencyTopup(Object.values(CurrencyTopup));

            if (Object.keys(paymentMethod).length > 0) {
              setPaymentMethods(paymentMethod);

              setSelectedPaymentMethod(Object.keys(paymentMethod)[0]);
            }

            if (promotions?.length > 0) {
              setPromotions(promotions);
              if (!autoUpdatePromotionList) setSelectedPromotion(promotions[0].id);
              else setSelectedPromotion(0);
            }

            // currency
            setCurrency(memberBalance?.currencyCode);

            // // bank type name
            setBankTypeName(paymentMethod?.bank?.name);

            setIsLoading(false);
            dispatch(closeLoadingScreen());
          } else {
            // fail
            setIsLoading(false);
            dispatch(closeLoadingScreen());
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        setIsLoading(false);
        dispatch(closeLoadingScreen());
      });
  };

  const onSubmit = () => {
    setIsSubmitting(true);

    if (selectedPaymentMethod === 'bank') {
      topupByBankTransfer();
    } else {
      topupByOnlinePayment();
    }
  };

  const handleConfirm = () => {
    if (isValid()) {
      setConfirmDeposit(true);
    }
  };

  const topupByBankTransfer = () => {
    const requestData = {
      'transaction-amount': parseFloat(depositAmount.toString().replaceAll(',', '')),
      // 'transaction-amount': amountInThousandths ? depositAmount / 1000 : depositAmount,
      'transaction-file_base64_1': bankSlip?.file?.base64,
      'transaction-promotion_id': selectedPromotion,
      'transaction-bank_account_id': selectedBank,
      'transaction-bank_type': selectedPaymentMethod,
      /**
       * set 0 in env if don't want reference number
       */
      ...(process.env.REACT_APP_REF_NUMBER_LENGTH > 0 && { 'transaction-reference_no': referenceNumber.trim() }),
    };

    conObj
      .post('transactions/topup.json', requestData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            // success
            // message to show
            // res.data?.data?.msg;
            const snackbarOptions = { variant: 'success', autoHideDuration: 5000 };
            enqueueSnackbar(res.data?.data?.msg, snackbarOptions);
            dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
            setIsSubmitting(false);
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        setConfirmDeposit(false);
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
        setIsSubmitting(false);
      });
  };

  const topupByOnlinePayment = () => {
    if (!selectedPaymentMethod) {
      enqueueSnackbar(translate('please_select_x', { x: translate('payment_method') }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      setIsSubmitting(false);
      return;
    }

    const requestData = {
      'transaction-amount': parseFloat(depositAmount.toString().replaceAll(',', '')),
      'transaction-promotion_id': selectedPromotion,
      'transaction-bank_account_id': selectedBank,
      'transaction-redirect_url': `${window.location.origin}/${params?.domain}/payStatus/${selectedPaymentMethod}`,
      // 'transaction-redirect_url': 'https://www.google.com',
    };

    if (selectedBankSource) {
      requestData['transaction-deposit_source_bank_code'] = selectedBankSource;
    }

    conObj
      .post(
        `${selectedPaymentMethod?.toString()?.trim()}/topup_${selectedPaymentMethod?.toString()?.trim()}.json`,
        requestData
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            // setIsSubmitting(false);
            if (selectedPaymentMethod === 'toppay') {
              setConfirmDeposit(false);
              setTopPayQrCode(res.data?.data?.redirectUrl);
            } else {
              window.location.replace(res.data?.data?.redirectUrl);
            }
          }
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log(`Server Error: ${err.message}`);
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
      });
  };

  // const getWithdrawRequirement = (transactionAmount, transactionPromotionId) => {
  //   setIsCalculatingReq(true);

  //   const requestData = {
  //     'transaction-amount': transactionAmount,
  //     'transaction-promotion_id': transactionPromotionId,
  //   };

  //   conObj
  //     .post('transactions/calculate_withdraw_requirement.json', requestData)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setWithdrawRequirement(res.data.data?.data);
  //       }
  //       setIsCalculatingReq(false);
  //     })
  //     .catch((err) => {
  //       setIsCalculatingReq(false);
  //       console.log(`Error: ${err.message}`);
  //     });
  // };

  // useEffect(() => {
  //   let valid = true;
  //   const _errors = {};
  //   setErrors({});

  //   const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);
  //   // promo minimum deposit
  //   const minDeposit = _selectedPromotion?.min_deposit;

  //   if (selectedBank) {
  //     if (!depositAmount) {
  //       valid = false;
  //       _errors.depositAmount = translate('x_is_required', { x: `${translate('deposit')} ${translate('amount')}` });
  //     } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < parseFloat(minDeposit)) {
  //       valid = false;
  //       _errors.depositAmount = `${translate('minimum_amount_is_x_y', { x: currency, y: minDeposit })}. (${translate(
  //         'from_selected_promotion'
  //       )})`;
  //     } else if (
  //       parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit ||
  //       parseFloat(depositAmount.toString().replaceAll(',', '')) > maximumDeposit
  //     ) {
  //       // if maximumDeposit === 0 means no min/max deposit
  //       if (maximumDeposit !== 0) {
  //         valid = false;
  //         _errors.depositAmount = translate('deposit_amount_between_x_and_y', { x: minimumDeposit, y: maximumDeposit });
  //       } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit) {
  //         valid = false;
  //         _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minimumDeposit });
  //       }
  //     } else {
  //       _errors.depositAmount = '';
  //     }
  //   }

  //   setErrors(_errors);

  //   const delayDebounceFn = setTimeout(() => {
  //     getWithdrawRequirement(parseFloat(depositAmount.toString().replaceAll(',', '')), selectedPromotion);
  //   }, 500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [depositAmount, selectedPromotion, minimumDeposit, maximumDeposit]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = '';
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log('Called', reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };

      console.log(fileInfo);
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const isValid = () => {
    let valid = true;
    const _errors = {};

    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);
    // promo minimum deposit
    const minDeposit = _selectedPromotion?.min_deposit;

    if (selectedBank) {
      if (!depositAmount) {
        valid = false;
        _errors.depositAmount = translate('x_is_required', { x: `${translate('deposit')} ${translate('amount')}` });
      } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < parseFloat(minDeposit)) {
        valid = false;
        _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minDeposit });
      } else if (
        parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit ||
        parseFloat(depositAmount.toString().replaceAll(',', '')) > maximumDeposit
      ) {
        // if maximumDeposit === 0 means no min/max deposit
        if (maximumDeposit !== 0) {
          valid = false;
          _errors.depositAmount = translate('deposit_amount_between_x_and_y', { x: minimumDeposit, y: maximumDeposit });
        } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit) {
          valid = false;
          _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minimumDeposit });
        }
      } else {
        _errors.depositAmount = '';
      }
    }

    // Bank specific validation
    if (selectedPaymentMethod === 'bank') {
      if (!selectedBank) {
        valid = false;
        _errors.selectedBank = translate('please_select_x', { x: translate('bank_account') });
      } else {
        _errors.selectedBank = '';
      }

      if (!bankSlip?.file) {
        valid = false;
        _errors.bankSlip = translate('please_upload_receipt');
      } else {
        _errors.bankSlip = '';
      }

      if (process.env.REACT_APP_REF_NUMBER_LENGTH > 0) {
        if (referenceNumber.trim().length < process.env.REACT_APP_REF_NUMBER_LENGTH) {
          valid = false;
          _errors.referenceNumber = translate('bank_ref_number_validation_x', {
            x: process.env.REACT_APP_REF_NUMBER_LENGTH,
          });
        } else {
          _errors.referenceNumber = '';
        }
      }
    } else if (selectedPaymentMethod !== 'bank') {
      if (!selectedBank && !bankList) {
        valid = false;
        setShowAlert({ show: true, message: translate('please_select_x', { x: translate('payment_method') }) });
      } else if (!selectedBank) {
        valid = false;
        _errors.selectedBank = translate('please_select_x', { x: translate('payment_method') });
      } else {
        _errors.selectedBank = '';
      }
    }

    setErrors(_errors);
    return valid;
  };

  const toggleDrawer = (open) => {
    setConfirmDeposit(open);
  };

  const handleFileDrop = (acceptedFiles, mediaType) => {
    const file = acceptedFiles[0];

    if (file) {
      const url = {
        preview: URL.createObjectURL(file),
      };
      getBase64(file)
        .then((result) => {
          file.base64 = result;
          console.log('File Is', file);
          setBankSlip({ file, filePreview: url.preview });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getPromotion = (amount) => {
    const amountFloat = parseFloat(amount.toString().replaceAll(',', ''));
    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

    let bonus = 0;
    if (_selectedPromotion.bonus_type === 'percent') {
      bonus = (_selectedPromotion.bonus / 100) * amountFloat;
    } else {
      bonus = _selectedPromotion.bonus;
    }

    const maxBonusAmount = _selectedPromotion?.max_bonus_amount;

    /**
     * if max_bonus_amount === 0 - no cap
     * else, set cap
     */
    if (parseFloat(maxBonusAmount) > 0 && bonus > parseFloat(maxBonusAmount)) {
      bonus = _selectedPromotion?.max_bonus_amount;
    }

    const minDeposit = _selectedPromotion?.min_deposit;

    /**
     * bonus only apply if deposit amount => minDeposit
     */
    if (amountFloat < parseFloat(minDeposit)) {
      bonus = 0;
    }

    return fCurrency(bonus);
  };

  const makeCurrencyTopup = (amount, index) => {
    const _amount = amount;
    // const _amount = amountInThousandths ? amount * 1000 : amount;

    return (
      <Grid item xl={4} lg={4} md={4} sm={4} xs={4} key={index}>
        <SuggestedTopupBox
          onClick={() => setDepositAmount(_amount)}
          sx={{
            borderColor: depositAmount === _amount ? 'rgb(34, 131, 246)' : 'transparent',
          }}
        >
          {selectedPromotion.toString() !== '0' && (
            <SmallPromoBox component="span">
              <span>+</span>
              {/* <span>{currency}</span> */}
              <span>{`${getPromotion(_amount)}`}</span>
            </SmallPromoBox>
          )}
          <SuggestedTopupTxt>{`${fCurrency(_amount)}`}</SuggestedTopupTxt>
          {depositAmount === _amount && (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: 'absolute',
                bottom: 0,
                color: 'rgb(34, 131, 246)',
                height: '16px',
                right: 0,
                width: '17px',
                zIndex: 1,
              }}
            >
              <path d="M17 0L0 16H12C14.7614 16 17 13.7614 17 11V0Z" fill="currentColor" />
              <path
                d="M9.35493 11.6942L11.1083 12.8822L13.6758 9.00007"
                stroke="white"
                strokeWidth="0.822792"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </SuggestedTopupBox>
      </Grid>
    );
  };

  const getDisplayDepositBonus = () => {
    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

    const depositAmountFloat = parseFloat(depositAmount.toString().replaceAll(',', ''));

    const bonusType =
      _selectedPromotion.bonus_type === 'percent'
        ? `${_selectedPromotion.bonus}%`
        : `${currency}${_selectedPromotion.bonus}`;

    let bonus = 0;

    if (_selectedPromotion.bonus_type === 'percent') {
      bonus = (_selectedPromotion.bonus / 100) * depositAmountFloat;
    } else {
      bonus = _selectedPromotion.bonus;
    }

    const maxBonusAmount = _selectedPromotion?.max_bonus_amount;
    /**
     * if max_bonus_amount === 0 - no cap
     * else, set cap
     */
    if (parseFloat(maxBonusAmount) > 0 && bonus > parseFloat(maxBonusAmount)) {
      bonus = _selectedPromotion?.max_bonus_amount;
    }

    const minDeposit = _selectedPromotion?.min_deposit;
    /**
     * bonus only apply if deposit amount => minDeposit
     */
    if (parseFloat(depositAmountFloat) < parseFloat(minDeposit)) {
      bonus = 0;
    }

    return (
      <BonusBox container>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography sx={{ fontWeight: 'inherit', color: '#fff' }}>{translate('deposit_bonus')}</Typography>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography sx={{ fontWeight: 'inherit', textAlign: 'center' }}>{`${bonusType}`}</Typography>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12} sx={{ wordBreak: 'break-all' }}>
          <Typography sx={{ fontWeight: 'inherit', textAlign: { sm: 'end', xs: 'start' } }}>{`+${currency} ${fCurrency(
            bonus
          )}`}</Typography>
        </Grid>
      </BonusBox>
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(`${translate('copied')}!`);
  };

  const getPlaceholder = (minimumDeposit, maximumDeposit) => {
    if (minimumDeposit > 0 && maximumDeposit > 0) {
      return `${translate('minimum')} ${minimumDeposit} ~ MAX ${maximumDeposit}`;
    }
    if (minimumDeposit === 0 && maximumDeposit === 0) {
      return `${translate('enter_amount')}`;
    }
    if (minimumDeposit > 0) {
      return `${translate('minimum')} ${minimumDeposit}`;
    }
    return `${translate('maximum')} ${maximumDeposit}`;
  };

  return isLoading ? (
    <DepositPlaceholder />
  ) : (
    <Box>
      {showAlert.show && (
        <Box
          className="alert-pulse"
          sx={{ backgroundColor: 'rgb(255 63 63 / 70%)', p: 2, position: 'relative', mb: 1 }}
        >
          <Stack direction="row" alignItems={'center'}>
            <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'white', flexGrow: 1 }}>
              {showAlert.message}
            </Typography>
            <Iconify
              icon={'ep:close'}
              width={24}
              height={24}
              sx={{ cursor: 'pointer' }}
              onClick={() => setShowAlert({ show: false, message: '' })}
            />
          </Stack>
        </Box>
      )}

      {Object.keys(paymentMethods)?.length > 0 && (
        <FormControl sx={{ mt: '10px' }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={selectedPaymentMethod}
            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
          >
            {Object.keys(paymentMethods).map((key, idx) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={translate(paymentMethods?.[key]?.name)}
                componentsProps={{
                  typography: {
                    fontSize: '14px',
                    fontWeight: 700,
                    color: 'white',
                  },
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}

      {bankList && (
        <Box>
          <Stack direction="row" sx={{ mt: 1 }}>
            <span style={{ color: 'red', marginRight: '4px' }}>*</span>
            <TypographyCustom sx={{ fontWeight: 700 }}>
              {selectedPaymentMethod === 'bank' ? translate('transfer_to') : translate('payment_method')}{' '}
            </TypographyCustom>
          </Stack>
          <StyledSelect value={selectedBank} onChange={(e) => setSelectedBank(e.target.value)} disableUnderline={true}>
            {bankList.map((item, index) => (
              <option key={index} value={item?.BankAccount?.id}>
                {item?.BankAccount?.label}
              </option>
            ))}
          </StyledSelect>
          {errors?.selectedBank && <span style={{ fontSize: '12px', color: 'red' }}>{errors?.selectedBank}</span>}
        </Box>
      )}

      {Object.keys(bankSourceList)?.length ? (
        <Box>
          <Stack direction="row" sx={{ mt: 1, alignItems: 'end' }}>
            <span style={{ color: 'red', marginRight: '4px' }}>*</span>
            <TypographyCustom sx={{ fontWeight: 700 }}>{translate('deposit_from_bank')}</TypographyCustom>
          </Stack>
          {/* <NativeSelect
            value={selectedBank}
            sx={{ width: '100%', color: 'white', fontSize: '14px' }}
            onChange={(e) => setSelectedBank(e.target.value)}
          >
            {bankList.map((item, index) => (
              <option key={index} value={item?.BankAccount?.id} style={{ color: 'black' }}>
                {item?.BankAccount?.label}
              </option>
            ))}
          </NativeSelect> */}
          <Select
            value={selectedBankSource}
            sx={{ width: '100%', color: 'white', fontSize: '14px' }}
            onChange={(e) => setSelectedBankSource(e.target.value)}
            displayEmpty
          >
            {Object.keys(bankSourceList).map((key, index) => (
              <MenuItem key={index} value={key}>
                {bankSourceList[key]}
              </MenuItem>
            ))}
          </Select>
          {errors?.selectedBank && <span style={{ fontSize: '12px', color: 'red' }}>{errors?.selectedBank}</span>}
        </Box>
      ) : (
        ''
      )}

      {selectedBank && (
        <>
          <SelectedBankBox>
            <Box sx={{ display: 'flex', float: 'right' }}>
              {selectedPaymentMethod === 'bank' && (
                <IconButton
                  size="small"
                  onClick={() => copyToClipboard(selectedBankData?.BankAccount?.account_number)}
                  sx={{ color: 'rgb(253, 169, 45)' }}
                >
                  <Iconify icon={'ph:copy'} />
                </IconButton>
              )}
              {selectedBankData?.BankAccount?.qrcode_photo_url ? (
                <IconButton
                  size="small"
                  onClick={() => setShowBankQrCode(selectedBankData?.BankAccount)}
                  sx={{ color: 'rgb(253, 169, 45)' }}
                >
                  <Iconify icon={'ic:baseline-qrcode'} />
                </IconButton>
              ) : (
                ''
              )}
            </Box>
            <Box>
              {selectedPaymentMethod === 'bank' && (
                <SmallBlackText>
                  {`${translate('bank_name')}: `}
                  <SmallBlackText component="span">{selectedBankData?.Bank?.name}</SmallBlackText>
                </SmallBlackText>
              )}
              <SmallBlackText>
                {`${selectedPaymentMethod !== 'bank' ? translate('payment_method') : translate('account_name')}: `}
                <SmallBlackText component="span">{selectedBankData?.BankAccount?.account_name}</SmallBlackText>
              </SmallBlackText>
              <SmallBlackText>
                {`${selectedPaymentMethod !== 'bank' ? translate('mode') : translate('account_number')}: `}
                <SmallBlackText component="span">{selectedBankData?.BankAccount?.account_number}</SmallBlackText>
              </SmallBlackText>
              {selectedBankData?.BankAccount?.bank_branch ? (
                <SmallBlackText>
                  Bank BSB:{' '}
                  <SmallBlackText component="span">{selectedBankData?.BankAccount?.bank_branch}</SmallBlackText>
                </SmallBlackText>
              ) : (
                ''
              )}
              {selectedBankData?.BankAccount?.pay_id ? (
                <SmallBlackText>
                  PayID: <SmallBlackText component="span">{selectedBankData?.BankAccount?.pay_id}</SmallBlackText>
                </SmallBlackText>
              ) : (
                ''
              )}
            </Box>
          </SelectedBankBox>
        </>
      )}

      {maintenanceRemark && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'black',
            p: 1.5,
            border: '1px solid rgb(242, 197, 86)',
            borderRadius: '0.7rem',
            position: 'relative',
            my: 1,
          }}
        >
          {/* <Iconify icon={'fluent:warning-28-filled'} sx={{ fontSize: '1.2rem', color: 'rgb(242, 197, 86)', m: 0 }} /> */}
          <Box sx={{ whiteSpace: 'pre-line', fontWeight: 900 }}>{renderWithLinks(maintenanceRemark) || ''}</Box>
        </Box>
      )}

      {bankInactiveInstruction && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'black',
            p: 1.5,
            border: '1px solid rgb(242, 197, 86)',
            borderRadius: '0.7rem',
            position: 'relative',
            my: 1,
          }}
        >
          {/* <Iconify icon={'fluent:warning-28-filled'} sx={{ fontSize: '1.2rem', color: 'rgb(242, 197, 86)', m: 0 }} /> */}
          <Box sx={{ whiteSpace: 'pre-line', fontWeight: '900' }}>{renderWithLinks(bankInactiveInstruction) || ''}</Box>

          {customWAButton?.url && customWAButton?.title ? (
            <Button
              fullWidth
              startIcon={<Iconify icon="ic:baseline-whatsapp" />}
              variant="contained"
              color={'inherit'}
              sx={{
                backgroundColor: 'rgb(0, 166, 80)',
                '&:hover': { backgroundColor: 'rgb(0, 166, 80)' },
                color: 'white',
                mt: 1,
                fontWeight: '900',
              }}
              onClick={() => window.open(customWAButton.url, '_blank')}
            >
              {customWAButton.title}
            </Button>
          ) : (
            ''
          )}
        </Box>
      )}

      {!isHideDeposit && (
        <>
          {/* {filteredPromotions?.length && ( */}
          <Box>
            <Stack direction="row" sx={{ mt: 2 }}>
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
              <TypographyCustom sx={{ fontWeight: 700 }}>{translate('select_bonus')}</TypographyCustom>
            </Stack>

            <StyledSelect
              value={selectedPromotion}
              onChange={(e) => setSelectedPromotion(e.target.value)}
              disableUnderline={true}
            >
              <option value={0}>{translate('no_promotion')}</option>
              {filteredPromotions?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.title}
                </option>
              ))}
            </StyledSelect>
          </Box>
          {/* )} */}

          <Stack direction="row" sx={{ mb: 1, mt: 2 }}>
            <span style={{ color: 'red', marginRight: '4px' }}>*</span>
            <TypographyCustom sx={{ fontWeight: 700 }}>{translate('amount')}</TypographyCustom>
          </Stack>

          <DepositInputBox direction={{ sm: 'row', xs: 'column' }}>
            <Stack direction="row" alignItems={'center'} spacing={2} flex={1}>
              <LargeBlackText>{currency}</LargeBlackText>
              <Stack flex={1}>
                <DepositInput
                  id="outlined-adornment-weight"
                  // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  value={fNumberCommaSeparated(depositAmount) !== '0' ? fNumberCommaSeparated(depositAmount) : ''}
                  placeholder={getPlaceholder(minimumDeposit, maximumDeposit)}
                  onChange={(e) => {
                    setDepositAmount(e.target.value?.slice(0, 13));
                  }}
                  sx={{
                    input: {
                      '&::placeholder': {
                        color: '#fff',
                        opacity: 0.3,
                        fontSize: '18px',
                      },
                    },
                  }}
                  autoFocus
                />
              </Stack>
            </Stack>
            {selectedPromotion.toString() !== '0' && (
              <Stack direction={{ sm: 'column', xs: 'row' }} sx={{ width: { sm: 'unset', xs: '100%' } }}>
                <SmallBlackText>{translate('extra')}</SmallBlackText>
                <SmallBlackText>{`+${currency}${getPromotion(depositAmount)}`}</SmallBlackText>
              </Stack>
            )}
          </DepositInputBox>
          {errors?.depositAmount && <span style={{ fontSize: '12px', color: 'red' }}>{errors?.depositAmount}</span>}

          {/* {withdrawRequirement && withdrawRequirement?.requirement !== 0 && (
            <Box sx={{ mt: '10px', display: 'flex', alignItems: 'center', fontWeight: 900 }}>
              <Typography
                sx={{
                  fontSize: { sm: '1.5rem', xs: '4.5vw' },
                  color: '#fda92d',
                  fontWeight: 900,
                }}
              >
                {`${translate('requirement_to_withdraw')}`}{' '}
                {withdrawRequirement?.calculation_type === 'turnover'
                  ? translate('total_bet_x', { x: memberBalance?.currencyCode })
                  : translate('x_of_y', {
                      x: translate('winning'),
                      y: memberBalance?.currencyCode,
                    })}
                {isCalculatingReq ? (
                  <Iconify icon={'eos-icons:loading'} sx={{ ml: 0.5 }} />
                ) : (
                  fNumberCommaSeparated(withdrawRequirement?.requirement)
                )}
              </Typography>
            </Box>
          )} */}

          <Box sx={{ mt: '10px' }}>
            <Grid container spacing={'20px'}>
              {overrideCurrencyTopup?.length
                ? overrideCurrencyTopup.map((item) => makeCurrencyTopup(item))
                : currencyTopup.map((item) => makeCurrencyTopup(item))}
            </Grid>

            {selectedPromotion.toString() !== '0' && getDisplayDepositBonus()}
          </Box>

          {selectedPaymentMethod === 'bank' && selectedBank && (
            <Box sx={{ mt: 2 }}>
              <Stack direction="row">
                <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                <TypographyCustom sx={{ fontWeight: 700 }}>{translate('upload_receipt')}:</TypographyCustom>
              </Stack>
              <UploadSingleFile
                accept="image/*"
                // files={bankSlip.value}
                // error={checkError}
                onDrop={(files) => handleFileDrop(files, 'bankSlip')}
                // helperText={
                //   checkError && (
                //     <FormHelperText error sx={{ px: 2 }}>
                //       {error?.message}
                //     </FormHelperText>
                //   )
                // }
                // {...other}
              />
              {errors?.bankSlip && <span style={{ fontSize: '12px', color: 'red' }}>{errors?.bankSlip}</span>}
              {bankSlip && (
                <Box>
                  <img
                    src={bankSlip?.filePreview}
                    style={{ maxWidth: '100%', mt: 1, mb: 2, maxHeight: '180px' }}
                    alt="Preview Bank Slip"
                  />
                </Box>
              )}

              {process.env.REACT_APP_REF_NUMBER_LENGTH > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Stack direction="row">
                    <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                    <TypographyCustom sx={{ fontWeight: 700 }}>
                      {translate('last_x_digit_bank_ref_number', { x: process.env.REACT_APP_REF_NUMBER_LENGTH })}:
                    </TypographyCustom>
                  </Stack>
                  <BankRefNumber
                    variant="outlined"
                    value={referenceNumber}
                    onChange={(e) => setReferenceNumber(e.target.value)}
                    placeholder={translate('last_x_digit_bank_ref_number', {
                      x: process.env.REACT_APP_REF_NUMBER_LENGTH,
                    })}
                  />
                  {errors?.referenceNumber && (
                    <span style={{ fontSize: '12px', color: 'red' }}>{errors?.referenceNumber}</span>
                  )}
                </Box>
              )}
            </Box>
          )}
        </>
      )}

      {!isHideDeposit && (
        <ConfirmButtonContainer container onClick={() => handleConfirm()}>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { sm: 'unset', xs: 'center' },
            }}
          >
            <DepositBtnText>{translate('deposit')}</DepositBtnText>
          </Grid>
          <Grid
            item
            xl={9}
            lg={9}
            md={9}
            sm={9}
            xs={12}
            sx={{ display: 'flex', justifyContent: { xl: 'end', lg: 'end', md: 'end', sm: 'end', xs: 'center' } }}
          >
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <DepositBtnAmount>{`${currency} ${fCurrency(depositAmount)}`}</DepositBtnAmount>
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="right-circle"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
                style={{ color: '#000' }}
              >
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm154.7 454.5l-246 178c-5.3 3.8-12.7 0-12.7-6.5v-46.9c0-10.2 4.9-19.9 13.2-25.9L566.6 512 421.2 406.8c-8.3-6-13.2-15.6-13.2-25.9V334c0-6.5 7.4-10.3 12.7-6.5l246 178c4.4 3.2 4.4 9.8 0 13z" />
              </svg>
            </Stack>
          </Grid>
        </ConfirmButtonContainer>
      )}

      {confirmDeposit && (
        <ConfirmDepositDrawer
          confirmDeposit={confirmDeposit}
          toggleDrawer={toggleDrawer}
          depositAmount={fCurrency(depositAmount)}
          extraAmount={selectedPromotion !== '0' && selectedPromotion !== 0 ? getPromotion(depositAmount) : 0}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      )}

      <CustomModal maxWidth="xs" open={!!showBankQrCode} onClose={() => setShowBankQrCode(null)}>
        <Stack sx={{ py: 5, px: 4, alignItems: 'center' }} spacing={2}>
          <Card sx={{ bgColor: 'white' }}>
            <Image src={showBankQrCode?.qrcode_photo_url} />
          </Card>

          <Typography textAlign={'center'}>{showBankQrCode?.label}</Typography>
        </Stack>
      </CustomModal>

      <PopupQrCode qrCodeString={topPayQrCode} setTopPayQrCode={setTopPayQrCode} />
    </Box>
  );
};

export default Deposit;
